import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // Handle URL anchor on page load
    if (window.location.hash) {
      const tab = document.querySelector(`[data-bs-toggle="tab"][data-bs-target="${window.location.hash}"]`)
      if (tab) {
        tab.click()
      }
    }

    // Update URL when tabs are clicked
    document.querySelectorAll('[data-bs-toggle="tab"]').forEach(tab => {
      tab.addEventListener('shown.bs.tab', (event) => {
        const hash = event.target.getAttribute('href')
        if (hash) {
          history.pushState(null, '', hash)
        }
      })
    })
  }
}

import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["item", "showAllText", "showSelectedText", "editButton", "form", "checkbox", "productCell", "counter"]
  static values = {
    selectedClass: {type: String, default: 'js-selected'},
    hideClass: {type: String, default: 'd-none'},
    editMode: {type: Boolean, default: false},
    expanded: {type: Boolean, default: false}
  }

  connect() {
    // Hide non-selected items by default
    this.hideNonSelected()
    this.updateCounter()

    // Listen for Turbo Stream updates
    document.addEventListener('turbo:before-stream-render', (event) => {
      const targetId = event.target.getAttribute('target')
      if (targetId && targetId.endsWith('_product_cell')) {
        const productType = targetId.replace('_product_cell', '')
        const row = document.getElementById(targetId).closest('tr')
        const buttonCell = row.querySelector('td:last-child')
        this.cleanupEditButtons(buttonCell)
      }
    })
  }

  editProduct(event) {
    event.preventDefault()
    const link = event.currentTarget
    const row = link.closest('tr')
    const productType = link.dataset.productType
    const nameCell = row.querySelector('td:first-of-type')
    const buttonCell = row.querySelector('td:last-of-type')

    // Store current name and button HTML for cancel action
    nameCell.dataset.currentName = nameCell.innerHTML
    buttonCell.dataset.editButton = buttonCell.innerHTML

    // Create action buttons
    const submitButton = document.createElement('a')
    submitButton.href = '#'
    submitButton.className = 'btn btn-link btn-sm p-0 me-2'
    submitButton.innerHTML = '<i class="fas fa-check text-success"></i>'
    submitButton.dataset.action = 'click->education-products-list#submitEdit'
    submitButton.dataset.productType = productType

    const cancelButton = document.createElement('a')
    cancelButton.href = '#'
    cancelButton.className = 'btn btn-link btn-sm p-0'
    cancelButton.innerHTML = '<i class="fas fa-times text-danger"></i>'
    cancelButton.dataset.action = 'click->education-products-list#cancelEdit'
    cancelButton.dataset.productType = productType

    // Hide edit button and show action buttons
    link.remove()
    buttonCell.appendChild(submitButton)
    buttonCell.appendChild(cancelButton)

    // Show loading state
    nameCell.innerHTML = '<div class="spinner-border spinner-border-sm" role="status"><span class="visually-hidden">Loading...</span></div>'

    // Fetch products and populate select
    this.fetchProducts(productType).then(products => {
      const select = document.createElement('select')
      select.className = 'form-select'
      select.dataset.productType = productType

      // Create a temporary div to parse the HTML and get text content
      const tempDiv = document.createElement('div')
      tempDiv.innerHTML = nameCell.dataset.currentName
      const currentProductName = tempDiv.textContent.trim()

      // Add empty option
      const emptyOption = document.createElement('option')
      emptyOption.value = ''
      emptyOption.textContent = '---'
      select.appendChild(emptyOption)

      // Add product options
      products.forEach(product => {
        const option = document.createElement('option')
        option.value = product.id
        option.textContent = product.name
        if (product.name === currentProductName) {
          option.selected = true
        }
        select.appendChild(option)
      })

      // Replace loading spinner with select
      nameCell.innerHTML = ''
      nameCell.appendChild(select)
      select.focus()
    })
  }

  cleanupEditButtons(buttonCell) {
    // Remove submit/cancel buttons
    const actionButtons = buttonCell.querySelectorAll('.btn-link')
    actionButtons.forEach(button => button.remove())

    // Restore edit button from stored HTML
    const editButtonHtml = buttonCell.dataset.editButton
    if (editButtonHtml) {
      buttonCell.insertAdjacentHTML('beforeend', editButtonHtml)
      delete buttonCell.dataset.editButton
    }
  }

  cancelEdit(event) {
    event.preventDefault()
    const row = event.currentTarget.closest('tr')
    const select = row.querySelector('select')
    const cell = select.closest('td')
    const buttonCell = event.currentTarget.closest('td')

    // Restore original content
    cell.innerHTML = cell.dataset.currentName
    this.cleanupEditButtons(buttonCell)
  }

  submitEdit(event) {
    event.preventDefault()
    const link = event.currentTarget
    const row = link.closest('tr')
    const productType = link.dataset.productType
    const nameCell = row.querySelector('td:first-of-type')
    const buttonCell = link.closest('td')
    const select = nameCell.querySelector('select')

    if (!select) return

    const productId = select.value
    const educationId = this.element.dataset.educationId

    // Show loading state
    nameCell.innerHTML = '<div class="spinner-border spinner-border-sm" role="status"><span class="visually-hidden">Loading...</span></div>'

    // Submit the change using Turbo
    const form = document.createElement('form')
    form.method = 'POST'
    form.action = `/educations/${educationId}/update_product`
    
    const methodInput = document.createElement('input')
    methodInput.type = 'hidden'
    methodInput.name = '_method'
    methodInput.value = 'PATCH'
    
    const productTypeInput = document.createElement('input')
    productTypeInput.type = 'hidden'
    productTypeInput.name = 'product_type'
    productTypeInput.value = productType
    
    const productIdInput = document.createElement('input')
    productIdInput.type = 'hidden'
    productIdInput.name = 'product_id'
    productIdInput.value = productId
    
    const csrfInput = document.createElement('input')
    csrfInput.type = 'hidden'
    csrfInput.name = 'authenticity_token'
    csrfInput.value = document.querySelector('meta[name="csrf-token"]').content
    
    form.appendChild(methodInput)
    form.appendChild(productTypeInput)
    form.appendChild(productIdInput)
    form.appendChild(csrfInput)
    
    // Submit form using Turbo
    Turbo.navigator.submitForm(form)
  }

  retryEdit(event) {
    event.preventDefault()
    this.editProduct(event)
  }

  async fetchProducts(productType) {
    try {
      const response = await fetch(`/products.json?category=${productType}`)
      if (response.ok) {
        return await response.json()
      }
      throw new Error('Failed to fetch products')
    } catch (error) {
      console.error('Error fetching products:', error)
      return []
    }
  }

  getEducationId() {
    const match = window.location.pathname.match(/\/educations\/(\d+)/)
    return match ? match[1] : null
  }

  toggle(event) {
    event.preventDefault()
    this.expandedValue = !this.expandedValue

    // Toggle visibility of non-selected items
    this.itemTargets.forEach(item => {
      if (!item.classList.contains(this.selectedClassValue)) {
        item.classList.toggle(this.hideClassValue)
      }
    })

    // Toggle text visibility
    this.showAllTextTarget.classList.toggle(this.hideClassValue)
    this.showSelectedTextTarget.classList.toggle(this.hideClassValue)

    // Show edit button only when list is expanded
    if (this.editButtonTarget) {
      this.editButtonTarget.classList.toggle(this.hideClassValue, !this.expandedValue)
    }
  }

  toggleEdit(event) {
    event.preventDefault()
    this.editModeValue = !this.editModeValue

    // Toggle checkbox visibility
    this.checkboxTargets.forEach(checkbox => {
      checkbox.classList.toggle(this.hideClassValue)
    })

    const button = event.currentTarget
    if (this.editModeValue) {
      button.textContent = button.dataset.educationProductsListSaveText
    } else {
      button.textContent = button.dataset.educationProductsListEditText
      this.submitForm(button)
    }
  }

  submitForm(button) {
    const form = this.formTarget
    Turbo.navigator.submitForm(form)
  }

  handleSubmitError(button) {
    button.textContent = button.dataset.educationProductsListSaveText
    this.editModeValue = true
  }

  updateUI() {
    this.itemTargets.forEach(item => {
      this.updateItem(item)
    })

    // Hide checkboxes
    this.checkboxTargets.forEach(checkbox => {
      checkbox.classList.add(this.hideClassValue)
    })

    this.editModeValue = false
    this.updateCounter()
  }

  updateItem(item) {
    const checkbox = item.querySelector('input[type="checkbox"]')
    const icon = item.querySelector('.d-flex i')

    if (checkbox && icon) {
      // Update selected class
      item.classList.toggle(this.selectedClassValue, checkbox.checked)

      // Update check icon
      icon.textContent = checkbox.checked ? '✓' : ''
      icon.classList.toggle('text-success', checkbox.checked)
      icon.style.width = '1rem'
      icon.style.display = 'inline-block'

      // Update parent link class if it exists
      const link = icon.closest('a')
      if (link) {
        link.classList.toggle('text-muted', !checkbox.checked)
      }
    }
  }

  updateCounter() {
    const selectedCount = this.itemTargets.filter(item => item.classList.contains(this.selectedClassValue)).length
    const template = this.counterTarget.textContent.replace(/\d+/, '{{count}}')
    this.counterTarget.textContent = template.replace('{{count}}', selectedCount)
  }

  hideNonSelected() {
    this.itemTargets.forEach(item => {
      if (!item.classList.contains(this.selectedClassValue)) {
        item.classList.add(this.hideClassValue)
      }
    })
  }
}

import {Controller} from "@hotwired/stimulus";

// TODO: Get rid of jQuery
export default class extends Controller {
  connect() {
    $(this.element).popover();
  }

  disconnect() {
    $(this.element).popover('dispose');
  }
}
